import "../../styles/spinner.css";

const Spinner = () => {
  return (
    <div className="spinner" aria-label="spinner-icon">
      <div className="spinner-circle"></div>
    </div>
  );
};

export default Spinner;
